<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet title="List Instansi Partner" />
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div class="col_tollbar">
              <v-btn
                depressed
                class="text-capitalize btn_tollbar rounded-lg bg_color1"
                @click="createData"
                dark
              >
                <v-icon> mdi-plus</v-icon> Tambah
              </v-btn>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          :single-select="false"
          class="elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    @click="updateData(item)"
                  >
                    <v-icon dark small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Edit Partner</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    color="red"
                    @click="deleteData(item)"
                  >
                    <v-icon dark small> mdi-delete </v-icon>
                  </v-btn></template
                >
                <span>Hapus Partner</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </div>
    <div class="pa-2">
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import Bullet from "../../components/Etc/bullet.vue";
import navbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { navbarProfile, Bullet },
  name: "listPartner",
  data() {
    return {
      loading: false,
      find: "",
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      dataSet: null,
      page: 1,
      limit: 10,
      menuLimit: false,
      headers: [
        { text: "Nama", value: "nama_instansi", width: "25%" },
        { text: "Jenis Instansi", value: "jenis_instansi" },
        { text: "Email", value: "email" },
        { text: "Telepon", value: "telepon" },
        { text: "Website", value: "website" },
        { text: "PIC", value: "pic" },
        { text: "Action", value: "actions", sortable: false },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Instansi Partner",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // get list partner
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/instansi-partner?page[size]=${this.limit}&page[number]=${this.page}&sort=${this.sort}&filter[nama_instansi]=${this.find}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    // tampilkan pop up update data expenditure
    updateData(item) {
      this.inventory = item;
      this.d_type = "u_partner";
      this.d_title = "Edit Instansi Partner";
      this.d_create = true;
      this.line = true;
    },

    // tampilkan pop up tambah data expenditure
    createData() {
      this.d_type = "c_partner";
      this.d_title = "Tambah Instansi Partner";
      this.d_create = true;
      this.line = true;
    },

    // ubah limit data yg tampil per page
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },

    // tampilkan pop up delete data expenditure
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/instansi-partner/delete`,
        deleteType: "Instansi Partner",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Instansi Partner";
      this.d_small = true;
      this.line = true;
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
